@import '../../variables.scss';

header.main.desktop {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    background-color: #ffffff;
}


header.main.desktop .logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 50px;
}
