@import '../../variables.scss';

.photo-header {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.photo-header .photo-wrapper {
    position:absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}


.photo-header .photo {
    border-radius: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.photo-header .square {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
}

.photo-header .shadow {
    background: linear-gradient(transparent, transparent 60%,#000000CC);
}