@import '../../variables.scss';

.universes-container .modal-content {
    margin: 20px;
    @include input-border;
    padding: 20px;
    background-color: #ffffff;
}

.universes-container .modal-content p {
    font-size: $base-font-size;
    font-style: bold;
    text-align: center;
    margin: 20px;
}

.universes-container .caption {
    text-align: center;
    display: block;
}