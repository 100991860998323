@import '../../variables.scss';

#recover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#recover .button {
    margin: 20px;
}
/*
.grey .modal-content,
.add .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.grey .modal-content p,
.add .modal-content p {
    margin: 20px;
}

.grey .modal-content .button,
.add .modal-content .button {
    margin: 20px;
}

.grey footer,
.add footer {
    border-top: 1px solid $main-color;
    padding: 10px;
}
*/