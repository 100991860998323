@import '../../variables.scss';

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
}

.login-container h2 {
    text-align: center;
}

.login-container .login-wrapper,
.login-container .register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-container .login-wrapper {
    width: 100%;

}

.login-container .social button {
    border: none;
    background-color: transparent;
    box-shadow: none;
} 

.login-container .google-login img {
    margin-right: 0;
    height: 20px;
}

.login-container .register .button {
    margin: 5px;
}

.login-container .divider {
    height: 1px;
    width: 100%;
    background-color: $light-color;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-container .divider span {
    padding: 5px;
    background-color: $back-grey;
} 


.login-container .facebook-login .fa-facebook{
    background-color: #3b5998;
    color: #ffffff;
    padding: 5px 2px 0 10px;
    font-size: $huge-font-size;
}