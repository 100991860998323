@import '../../variables.scss';

.change-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.change-container p {
    text-align: center;
    margin: 20px 0;
}

.change-container .form-field-wrapper {
    width: 100%;
}

.change-container .bottom .button {
    margin-bottom: 20px;
}