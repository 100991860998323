.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: none;
}

.modal-overlay {
    width: 100vw;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (min-device-width: 1200px) {
    .modal-overlay {
        background-color: rgba(0,0,0,.8);
    }
}
