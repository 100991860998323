@import '../../variables.scss';

header.main.mobile {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

header.main.mobile > div {
    flex: 0 0 33%;
}

header.main.mobile .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}

header.main.mobile .logo img {
    height: 25px;
    margin-right: 10px;
}