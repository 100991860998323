@import '../../variables.scss';

.login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-form .error {
    color: $error-color;
    text-align: center;
    margin-bottom: 10px;
} 
.login-form .form-field-wrapper {
    width: 100%;
}

.login-form .wrapper,
.login-form .form-field-wrapper {
    margin: 5px;
}


.login-form .button {
    margin-bottom: 20px;
}