@import '../../variables.scss';

.radio-options {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.radio-options.vertical {
    flex-direction: column;
}

.radio-options.vertical li {
    margin-bottom: 10px;
}

.radio-options li {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.radio-options li label {
    flex:1;
    font-size: $base-font-size;
}
.radio-options li span {
    margin: 5px 0 10px 27px;
    font-size: $secondary-font-size;
    color: $light-color;
}

.radio-options li::before {
    content: ' ';
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 1px solid $main-color;
    display: block;
    margin-right: 5px;
}

.radio-options li.selected::before {
    content: ' ';
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 1px solid $main-color;
    display: block;
    margin-right: 5px;
    background: radial-gradient($main-color, $main-color 50%,#ffffff 50%, #ffffff);
}