@import '../../variables.scss';

.transaction-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.transaction-details-container .prices {
    margin-top: 10px;
}

.transaction-details-container .prices .price-line {
    flex:0 1 0px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.transaction-details-container .prices .price-line .amount {
    width: 90px;
    text-align: right;
}

.transaction-details-container .prices .total {
    border-top: 1px solid $dark-color;
    padding-top: 5px;
}

.transaction-details-container .prices .total .amount,
.transaction-details-container .prices .total .label {
    font-size: $title-font-size;
}