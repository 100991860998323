@import 'variables.scss';
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*
End reset
*/

* {
  box-sizing: border-box;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  display: none;
}

*:focus {
  outline: none;
}

@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,700&display=swap');
body {
  margin: 0;
  font-family:  'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: $text-color;
}

h2 {
  @include title;
}

h3 {
  @include subtitle;
}

textarea,
input[type="date"] {
  font-family:  'Ubuntu', sans-serif;
}

body,  input, p, span, button, select, textarea {
  font-size: $base-font-size;
  color: $text-color;
}

select {
  -webkit-appearance:none;
}

button {
  border: none;
  background-color: transparent;
}

textarea:focus, input:focus{
  outline: none;
}

a,a:active, a:hover, a:visited {
  color: $main-color;
  text-decoration: none;
}

strong {
  font-weight: bold;
}

.popup {
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  margin: 20px;
  padding: 40px 20px;
  border: 1px solid #3C3B3B;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.popup p {
  text-align: center;
  margin-bottom: 40px;
}
