@import '../../variables.scss';

.chat-container {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}

.chat-container .article-summary-container {
    margin: 10px 0;
    padding: 10px;
    background-color: #ffffff;
    border-radius: $default-radius;
}

.chat-container .chat-messages {
    flex:1;
    overflow-y: scroll;
}

.chat-container .chat-messages .divider {
    width: 100%;
    text-align: center;
    font-size: $small-font-size;
    color: $light-color;
    margin: 20px 0 10px 0;
}

.chat-container .chat-input {
    display: flex;
    align-items: flex-end;
    padding: 10px 0;
}

.chat-container .chat-input textarea {
    flex: 1;
    padding: 10px;
    max-height: 150px;
    border-radius: $default-radius;
    height: 40px;
    overflow: auto;
}

.chat-container .chat-input .button {
    width: 40px;
    height: 40px;
    min-width: 0;
    min-height: 0;
    padding: 0;
    margin-left: 10px;
    justify-content: center;
}

.chat-container .chat-input .button .arrow {
    margin: 0;
}