@import '../../variables.scss';

.check-options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
}

.check-options li {
    flex: 0 0 50%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.check-options li::before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    border: 1px solid $main-color;
    margin-right: 10px;
    flex: 0 0 auto;
    
}

.check-options li.selected {
    background: url('/tick.svg') no-repeat 3px center ;
}

@media screen and (min-device-width: 1200px) {
    .check-options li {
        flex: 0 0 33%;
        padding-right: 10px;
    }
}