@import '../../variables.scss';

.nb-items-containter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.nb-items-containter .increase,
.nb-items-containter .decrease {
    background-color: $main-color;
    color: #ffffff;
    padding: 5px;
    font-size: $huge-font-size;
    font-weight: bold;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $default-radius;
}

.nb-items-containter .items.empty img {
    filter: opacity(0.1);
}

.nb-items-containter .items {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    min-height: 30px;
}

.nb-items-containter .items .item {
    flex: 0 1 auto;
    padding: 1px;
}

.nb-items-containter .items .item img{
    width: 100%;
    max-width: 20px;
}

.nb-items-containter .number {
    @include default-input;
    margin: 0 10px;
}