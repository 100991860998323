@import '../../variables.scss';

.checkout-container {
    flex:1;
    min-height: 0;
    display: flex;
    flex-direction: column;
}

.checkout-container .scroller {
    flex: 1;
    overflow-y: scroll;
    padding: 0 20px;
}

.checkout-container .ad-summary-container {
    padding: 10px;
    background-color: #ffffff;
    border-radius: $default-radius;
}

.checkout-container .summary {
    padding: 10px;
    background-color: #ffffff;
    border-radius: $default-radius;
}
.checkout-container .price-container {
    margin-top: 10px;
}

.checkout-container .shipping-options-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.checkout-container .shipping-options {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.checkout-container .shipping-options li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0;
    flex: 1 1 0px;
}

.checkout-container .shipping-options li .radio {
    width: 20px;
    height: 20px;
    border: 1px solid $main-color;
    border-radius: 20px;
}

.checkout-container .ship-option.error label>.label {
    color: $error-color;
}

.checkout-container .shipping-options li.selected .radio {
    background: radial-gradient($main-color, $main-color 50%,#ffffff 50%, #ffffff);
}

.checkout-container .shipping-options li .label {
    margin: 0 0 0 5px;
}

.checkout-container .shipping-options li .price {
    color: $main-color;
}

.checkout-error {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.checkout-error li {
    color: $main-color;
    font-size: $title-font-size;
    text-align: center;
}

.checkout-error .button {
    margin-top: 20px;
}