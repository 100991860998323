@import '../../variables.scss';

.check-box::before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    border: 1px solid $main-color;
    margin-right: 10px;
}

.check-box.checked {
    background: url('/tick.svg') no-repeat 3px top ;
}