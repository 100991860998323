@import '../../variables.scss';

.burger .container {
    position: relative;
    transition-property: background-color;
    transition-duration: 0.1s;
    transition-timing-function: linear;
}

.burger .control {
    margin: 10px;
    display: flex;
    height: 20px;
    width: 23px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.burger .control span {
    transform-origin: left;
    display: block;
    background-color: $main-color;
    border-radius: 3px;
    height: 3px;
    width: 100%;
    margin-bottom: 5px;
    transition-property: transform opacity;
    transition-duration: 0.1s;
    transition-timing-function: linear;
}

.burger .control span:last-of-type {
    margin: 0;
}

.burger input {
    display:none;
}

.burger #menu-toggle:checked ~ .container {
    position: absolute;
    opacity: 1;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: 1;
}

.burger #menu-toggle:checked ~ .container .control span:nth-child(1) {
    transform: rotate(45deg);
    
}
.burger #menu-toggle:checked ~ .container .control span:nth-child(2) {
    opacity: 0;
}

.burger #menu-toggle:checked ~ .container .control span:nth-child(3) {
    transform: rotate(-45deg);
}

.burger #menu-toggle:checked ~ .container nav {
    display: flex;
    
}

.burger .container nav {
    display: none;
}

.burger nav ul {
    margin: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.burger nav ul li {
    border-bottom: 1px solid $section-border-empty;
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.burger nav ul li a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.burger nav ul li a span {
    padding: 10px;
    padding-top: 0;
    flex: 1;
}

.burger nav ul li a::after {
    content: '>';   
    transform: scaleX(0.5);
    font-family: 'arial';
    display: block;
    font-weight: bold;
    color: $main-color;
    font-size: 24px;
    font-stretch: extra-condensed;
    flex: 0 0;
    padding: 10px;
    padding-top: 0;
}

.burger nav ul li a.current::after {
    color: $disabled-text;
}

.burger nav ul li a,
.burger nav ul li a:hover,
.burger nav ul li a:visited {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 15px;
    color: $text-color;
}

.burger nav ul li a.current,
.burger nav ul li a.current:hover,
.burger nav ul li a.current:visited {
    color: $disabled-text;
}
