.youzd-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
}

.youzd-form .scroller {
    position: relative;
    flex: 1;
    overflow-y: hidden;
}

.youzd-form .scroller .shade {
    position: absolute;
    bottom:0;
    width: 100%;
    height: 50px;
    background: linear-gradient(rgba(247,247,247,0),#f7f7f7 90%, #f7f7f7) ;
    z-index:1;
}

.youzd-form .scroller .form-body {
    overflow-y: scroll;
    height: 100%;
    padding-bottom: 20px;
}

@media screen and (min-device-width: 1200px) {
    .youzd-form {
        min-height: auto;
        width: 100%;
        margin: 0 auto;
        align-items: center;
    }  
    .youzd-form .scroller .shade {
        display: none;
    }
    .youzd-form .scroller .form-body {
        padding-bottom: 0;
    }
    /*
    .youzd-form .form-body {
        max-width: 600px;
    }*/
}




