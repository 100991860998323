
@import '../../variables.scss';

.color-picker .colors {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 20px;
    width: 250px;
    margin: 0 auto;
}

.color-picker .colors li {
    flex: 0 0 50%;
    display: flex;
    align-items: center;
}

.color-picker .colors li div {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    position: relative;
    margin: 10px;
    margin-left: 0;
}

.color-picker .colors li.selected div::before {
    content: ' ';
    position: absolute;
    top: -5px;
    left: -5px;
    width: 34px;
    height: 34px;
    border-radius: 34px;
    border: 3px solid $main-color;
}

.color-picker .caption {
    display: block;
    text-align: center;
}

.color-picker .colors .black {
    background-color: black;
}
.color-picker .colors .white {
    background-color: white;
    border: 1px solid $hairline-color;
    box-sizing: border-box;
}
.color-picker .colors .grey {
    background-color: silver;
}
.color-picker .colors .red {
    background-color: red;
}
.color-picker .colors .orange {
    background-color: orange;
}
.color-picker .colors .yellow {
    background-color: yellow;
}
.color-picker .colors .green {
    background-color: green;
}
.color-picker .colors .blue {
    background-color: blue;
}
.color-picker .colors .purple {
    background-color: purple;
}
.color-picker .colors .pink {
    background-color: pink;
}
.color-picker .colors .brown {
    background-color: brown;
}
.color-picker .colors .wood {
    background: url('/wood.png') center center/cover no-repeat;
}
.color-picker .colors .transparent {
    background: url('/transparent.png') center center/cover no-repeat;
}
.color-picker .colors .copper {
    background: url('/copper.png') center center/cover no-repeat;
}
.color-picker .colors .silver {
    background: url('/silver.png') center center/cover no-repeat;
}
.color-picker .colors .gold {
    background: url('/gold.png') center center/cover no-repeat;
}

.color-picker .modal-content {
    margin: 20px;
    @include input-border;
    padding: 20px;
    background-color: #ffffff;
}

.color-picker .modal-content p {
    font-size: $base-font-size;
    font-style: bold;
    text-align: center;
    margin: 20px;
}