@import '../../variables.scss';

.welcome {
    padding: 20px;
}

.welcome .button {
    margin: 20px 0;
}

@media screen and (min-device-width: 1200px) {
    .welcome {
        min-height: 400px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .welcome .main {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .starter-container {
        width: 400px;
        margin-bottom: 50px;
    }

    .welcome .main .intro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .welcome .main .intro h2 {
        color: $text-color;
        margin-top: 15px;
        text-align: center;
    }


    .welcome .main .vendre {
        flex: 0 0 50%;
        margin: 0 50px;
    }
}