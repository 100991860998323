@import '../../variables.scss';


.article-shipping-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.article-shipping-container .article-shipping li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0 5px 25px;
    flex: 1 1 0px;
    background: url('/tick.svg') no-repeat left center;
}

.article-shipping-container .article-shipping {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.article-shipping-container .article-shipping li .price {
    color: $main-color;
    font-weight: bold;
}

.article-shipping-container .please-login {
    text-align: center;
    margin: 10px 0;
    color: $main-color;
    text-decoration: underline;
    cursor: pointer;
}