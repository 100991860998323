@import '../../variables.scss';

.address-line {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
}

.address-line input, 
.address-line select {
    flex: 1;
    @include input-border;
    padding: 5px;
}
