@import '../../variables.scss';

.flat-menu ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flat-menu ul li {
    margin: 10px 15px;
}

.flat-menu ul li,
.flat-menu ul li a {
    display: flex;
    align-items: center;
}

.flat-menu ul li a {
    text-decoration: none;
}

.flat-menu ul li a img.avatar {
    max-height: 40px;
    margin: 0 5px;
    border-radius: 40px;
    border: 1px solid $light-color;
}