@import '../../variables.scss';

.chat-message-container {
    display: flex;
    width: 100%;
}

.chat-message-container.self {
    justify-content: flex-end;
}

.chat-message-container.other {
    justify-content: flex-start;
}

.chat-message-container pre {
    padding: 10px;
    max-width: 80%;
    background-color: #ffffff;
    margin: 5px;
    border-radius: 10px;
    white-space: pre-wrap;
}

.chat-message-container.self pre {
    border-bottom-right-radius: 0;
}

.chat-message-container.other pre {
    border-bottom-left-radius: 0;
    background-color: $back-darker-grey;
}