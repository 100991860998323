@import '../../variables.scss';

.attributes-frame {
    border: 2px solid $section-border-empty;
    margin-right: 20px;
    border-left: none;
    border-top-right-radius: $section-radius;
    border-bottom-right-radius: $section-radius;
    padding: 0 20px;
    margin-bottom: 10px;
    background-color: #ffffff;
    @include animate-collapse;
}

.attributes-frame.valid {
    border-color: $section-border-valid;
    margin-right: 60px;
}

.attributes-frame.active {
    @include animate-expand;
    border-color: $section-border-active;
    margin-right: 20px;
}

.attributes-frame .content {
    opacity: 0;
    @include animate-hide;
    display: flex;
    flex-direction: column;
}

.attributes-frame.active .content {
    opacity: 1;
    @include animate-visible;
    transition-timing-function: linear;
    padding-bottom: 20px;
}

.attributes-frame h3 {
    margin-bottom: 5px;
}

.attributes-frame .summary {
    @include small-text;
    margin-right: 10px;
}

.attributes-frame header {
    min-height: 64px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.attributes-frame header .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}


.attributes-frame.valid .arrow {
    position: relative;
    @include animate-toggle;
    right: -55px;
}

.attributes-frame.active .arrow {
    position: relative;
    right: 0;
}
.attributes-frame .content.hidden {
    display: none;
}

@media screen and (min-device-width: 1200px) {
    .attributes-frame {
        margin-left: 20px;
        border-left: 2px solid $section-border-empty;
        border-top-left-radius: $section-radius;
        border-bottom-left-radius: $section-radius;
    }
}