@import '../../variables.scss';

.edit-attributes {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.edit-attributes .main-attributes {
    background-color: $back-grey;
    padding: 20px 0;
}

.edit-attributes h2 {
    margin-top: 10px;
}

.edit-attributes .photos-container {
    margin-bottom: 20px;
}

.edit-attributes .photos-container h2 {
    margin-top: 0;
}

.edit-attributes .photo-editor .photos {
    padding: 0 20px;
    position: relative;
}

.edit-attributes .photo-editor {
    position: relative;
}

.edit-attributes .photo-action  {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.edit-attributes .photo-action > * {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.edit-attributes .shipping .location .label {
    font-size: $base-font-size;
}

.edit-attributes .shipping .location .caption {
    margin-bottom: 10px;
}

.edit-attributes .main-attributes .caption,
.edit-attributes .main-attributes h2 {
    padding: 0 20px;
    display: block;
}

.edit-attributes .title-edit {
    margin-bottom: 0;
    padding: 0 20px;
}


.edit-attributes 

.edit-attributes .details {
    padding: 10px 0 50px 0;
    background-color: $back-grey;
}

.edit-attributes .details h2 {
    margin: 10px 20px 20px 20px;
}

.edit-attributes .categories .categories-container {
    margin: 10px 0 20px 0;
}

.edit-attributes .form-body {
    position: relative;
}

.edit-attributes .attributes-frame p {
    margin: 10px 0;
}

.edit-attributes .cta {
    z-index: 2;
}

.edit-attributes .attributes-frame .button {
    max-width: 220px;
    align-self: center;
}

@media screen and (min-device-width: 1200px) {
    .edit-attributes .attributes-frame .button {
        align-self: flex-end;
    }
    .edit-attributes .attributes-content {
        max-width: 500px;
    }
    .edit-attributes .photos-container .attributes-content {
        max-width: inherit;
        width: 100%;
    }
    .edit-attributes .photos-container .gallery {
        justify-content: flex-start;
        margin-left: 20px;
    }
    .edit-attributes .scroller,
    .edit-attributes .main-attributes {
        width: 800px;
    }
}