@import '../../variables.scss';

.price-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.price-container .start,
.price-container .pad,
.price-container .current {
    width: 90px;
}

.price-container .pad,
.price-container .start {
    margin-left: -90px;
}


.price-container .tag {
    border: 1px solid $dark-color;
    border-radius: 10px;
    padding: 3px 5px 2px 5px;
    font-size: $small-font-size;
    text-align: center;
}

.price-container .current-price {
    text-align: center;
    font-size: $huge-font-size;
}

.price-container .current-price,
.price-container .current-price .decimal {
    color: $main-color;
}

.price-container .decimal {
    font-size: $secondary-font-size;
    margin-left: -1px;
}

.price-container .start {
    display: flex;
    justify-content: center;
}

.price-container .current-price {
    margin: 5px 0;
}

.price-container .start .striked {
    position: relative;
    margin: 5px 0;
    font-size: $huge-font-size;
}

.price-container .start .striked::after {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: $main-color;
    top:50%;
    left: 0;
}

.price-container.last .tag {
    border-color: $main-color;
    color: $main-color;
}

