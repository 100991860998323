@import '../../variables.scss';

.edit-profile hr {
    background-color: $light-color;
    border: none;
    height: 1px;
    width: 100%;
    margin: 20px 0 0 0;
}

.edit-profile .photo-editor .add {
    @include input-border;
}

.edit-profile .form-body {
    max-width: 600px;
    padding: 0 20px 50px 20px;
}

.caption {
    @include secondary-text;
}

.edit-profile .form-field-wrapper.address {
    margin-bottom: 10px;
}
.edit-profile .floor-edit {
    margin-top: 0;
}

.edit-profile .form-field .caption {
    margin-top: 5px;
}

.edit-profile .photo-editor {
    margin: $form-spacing;
}

.edit-profile .photos {
    justify-content: center;
}