@import '../../variables.scss';

.add-photos .form-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.add-photos {
    padding: 20px 0 0 0;
}

.add-photos .form-body .caption {
    margin: 10px 20px;
    text-align: center;
}

.add-photos .form-body.has-capture {
    justify-content: flex-start;
}

.add-photos .capture-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px 20px 20px;
    position: relative;
}

.add-photos .capture-wrapper .capture {
    width: 100%;
    border-radius: $default-radius;
}

.add-photos .capture-wrapper .control {
    position: absolute;
    bottom: 0;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: radial-gradient(#ffffff, #ffffff 60%,#000000 60%, #000000);
    border: 4px solid #ffffff;
    margin-bottom: 40px;
}