@import '../../variables.scss';

.user-info-container {
    display: flex;
    align-items: center;
}


.user-info-container .avatar {
    border-radius: 50px;
    margin-right: 10px;
    overflow: hidden;
    background-color: #ffffff;
}

.user-info-container .avatar img {
    width: 50px;
    height: 50px;
    
}