@import '../../variables.scss';

.price-edit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    background: url('/bg-price.png') no-repeat right top;
}

.price-edit h2 {
    margin-bottom: 10px;
    padding: 0;
}
.price-edit header {
    padding-right: 60px;
}
.price-edit .until,
.price-edit .prices {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 20px;
}
.price-edit .until {
    flex-wrap: wrap;
}
.price-edit .until .errors {
    width: 100%;
    text-align: center;
}

.price-edit .until.error input {
    border-color: $error-color;
}

.price-edit .prices {
    margin-bottom: 0;
}

.price-edit .form-group {
    margin-top: 0;
}

.price-edit .prices .form-field-wrapper {
    @include input-border;
    background-color: #ffffff;
    padding: 20px 10px;
    width: 50%;
    margin: 10px 0;
}

.price-edit .prices .end,
.price-edit .until input {
    border-color: $main-color;
}

.price-edit .prices .form-field-wrapper.error {
    border-color: $error-color
}

.price-edit .prices .form-field-wrapper.warning {
    border-color: $warning-color
}
.price-edit .prices label .label {
    text-align: center;
    font-size: $small-font-size;
}

.price-edit .prices .form-field input {
    border: none;  
    text-align: center; 
    padding: 0;
    min-height: auto;
    font-size: 24px;
    width: auto;
}

.price-edit .messages {
    text-align: center;
    padding: 0 20px;
}

.price-edit .prices .messages {
    width: 100%;
}

.price-edit .messages .errors {
    color: $error-color;
}
.price-edit .messages .warnings {
    color: $warning-color;
}

.price-edit .until {
    margin-top: 10px;
}

.price-edit .until .form-field.inline {
    justify-content: center;
}

.price-edit .until .label,
.price-edit .until .input-wrapper {
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
} 
.price-edit .until input {
    text-align: center;
    padding: 0;
    margin-left: 10px;
    flex: 1;
}

@media screen and (min-device-width: 1200px) {
    .price-edit {
        background: none;
    }

    .price-edit .prices .end,
    .price-edit .until input {
        border-color: $input-border-color;
    }
}
