@import '../../variables.scss';

.auto-suggest {
    display: flex;
    flex-direction: column;
}

.auto-suggest .suggestions.empty {
    display:none;
}

.auto-suggest .suggestions {
    border: 1px solid $input-border-color;
    border-width:  1px 0 0 1px;
}
.auto-suggest .suggestions li {
    background-color: #eeeeee;
    border: 1px solid $input-border-color;
    border-width: 0 1px 1px 0;
    padding: 5px;
}

.auto-suggest .suggestions li span {
    font-weight: bold;
    background-color: #ffff00;
}
.auto-suggest .suggestions li.highlight {
    background-color: #ffffff;
}