@import '../../variables.scss';


.article-summary-container {
    display: flex;
    width: 100%;
}

.article-summary-container .image .photo {
    width: 50px;
    height: 50px;
}

.article-summary-container .image {
    margin-right: 10px;
}

.article-summary-container h2 {
    color: $text-color;
    margin-bottom: 5px;
}

