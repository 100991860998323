@import '../../variables.scss';

.dimensions .width .label img {
    transform: rotate(90deg);
}

.dimensions .label {
    display: flex;
    align-items: center;
}

.dimensions .precision {
    margin-top: 0;
}

.dimensions input {
    width: 100px;
}

.dimensions .label img {
    margin: 10px;
}

.dimensions .form-field.reverse {
    justify-content: flex-end;
}

.dimensions textarea {
    min-height: 120px;
}
