$main-color: #ff0c67;
$dark-color: #626262;
$darker-color: #292929;
$light-color: #A0A0A0;
$disabled-color: #E6E6E6;
$back-grey: #f7f7f7;
$back-darker-grey: #e3e3e3;
$error-color: #ff0000;
$warning-color: #ff8800;
$info-color: #00cc00;
$inverted-text: #ffffff;
$hairline-color: rgba(200,200,200,0.2);


$disabled-text: $light-color;
$text-color: $dark-color;
$text-secondary: $light-color;


$input-border-color: $light-color;
$section-border-empty: $light-color;
$section-border-active: $light-color;
$section-border-valid: $main-color;

$button-font-size: 15px;
$base-font-size: 14px;
$title-font-size: 17px;
$huge-font-size: 20px;
$subtitle-font-size: 13px;
$small-font-size: 12px;
$micro-font-size: 10px;
$secondary-font-size: 13px;

$default-radius: 10px;
$section-radius: 32px;

$form-spacing: 20px 0;

@mixin input-border {
    border: 2px solid $input-border-color;
    border-radius: $default-radius;
}

@mixin default-input {
    @include input-border;
    padding: 10px;
    background-color: #ffffff;
}

@mixin small-text {
    font-size: $small-font-size;
    color: $dark-color;
}

@mixin secondary-text {
    font-size: $secondary-font-size;
    color: $text-secondary;
}

@mixin title {
    color: $main-color;
    font-size: $title-font-size;
    margin-bottom: 10px;
}

@mixin subtitle {
    font-size: $subtitle-font-size;
    font-weight: bold;
    text-transform: uppercase;
}

@mixin animate-expand {
    transition-property: margin height ;
    transition-delay: 0;
    transition-duration: 0.1s;
    transition-timing-function: linear;
}

@mixin animate-collapse {
    transition-property: margin height ;
    transition-delay: 0.1s;
    transition-duration: 0.1s;
    transition-timing-function: linear;
}

@mixin animate-visible {
    transition-property: opacity;
    transition-delay: 0.15s;
    transition-duration: 0.2s;
    transition-timing-function: linear;
}

@mixin animate-hide {
    transition-property: opacity;
    transition-delay: 0;
    transition-duration: 0.1s;
    transition-timing-function: linear;
}

@mixin animate-toggle {
    transition-property: right opactity;
    transition-duration: 0.2s;
    transition-timing-function: linear;
}