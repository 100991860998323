@import '../../variables.scss';

.youzd-screen.grey {
    background-color: $back-grey;
}

.youzd-screen {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

@media screen and (min-device-width: 1200px) {
    .youzd-screen {
        border: 1px solid $back-grey;
    }  
}