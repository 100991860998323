@import '../../variables.scss';


.form-field-wrapper {
    margin: $form-spacing;
}

.form-field {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.form-field.inline {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.form-field.reverse {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

.form-field .label {
    @include small-text;
    margin-bottom: 10px;
}

.form-field .label .mandatory {
    color: $main-color;
}

.form-field.big .label,
.form-field.reverse .label,
.form-field.inline .label {
    font-size: $base-font-size;
    color: $dark-color;
}
.form-field.reverse .label,
.form-field.inline .label {
    margin: 0;
}

.form-field textarea,
.form-field select,
.form-field input {
    @include default-input;
    min-height: 45px;
}

.form-field-wrapper.error textarea,
.form-field-wrapper.error select,
.form-field-wrapper.error input {
    border-color: $error-color;
}

.form-field-wrapper .errors {
    color: $error-color;
}

.form-field-wrapper.warning textarea,
.form-field-wrapper.warning select,
.form-field-wrapper.warning input {
    border-color: $warning-color;
}

.form-field-wrapper .errors,
.form-field-wrapper .infos,
.form-field-wrapper .warnings {
    margin-top: 10px;
}
 
.form-field-wrapper .warnings {
    color: $warning-color;
}
    
.form-field-wrapper.info select,
.form-field-wrapper.info input {
    border-color: $info-color;
}

.form-field-wrapper .infos {
    color: $info-color;
}
