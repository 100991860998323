@import '../../variables.scss';

.likes-container {
    display: flex;
    padding: 10px;
    background-color: $back-grey;
    border-top-right-radius: 10px;
}

@keyframes pulse {
    0% {
      filter: opacity(100%);
    }
    50% {
      filter: opacity(0);
    }
    100% {
        filter: opacity(100%);
      }
  }

.likes-container .icon.loading {
    animation: pulse 0.5s infinite;
}

.likes-container .icon {
    margin-right: 5px;
}