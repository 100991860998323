@import '../../variables.scss';

.starter {
    background-color: $main-color;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.starter .slider {
    display:flex;
    width: 100%;
} 

.starter .right-control,
.starter .left-control {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.starter .header-wrapper {
    position: relative;
}

.starter .header-wrapper,
.starter .header {
    display: flex;
    overflow: hidden;
} 

.starter h2 {
    width: 100%;
    color: #ffffff;
    padding: 10px 20px;
    text-align: center;
} 

.starter .header li {
    opacity: 0;
    margin-left: -100%;
    transition-property: opacity;
    transition-duration: 0.2s;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.starter li .slide {
    display: flex;
    padding: 20px 20px 0 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.starter .header li.current {
    opacity: 1;
    margin-left: 0;
} 

.starter .navigation {
    display: flex;
    justify-content: center;
    align-items: center;
}

.starter .navigation li {
    cursor: pointer;
    margin: 0 10px 20px 10px;
    width: 10px;
    height: 10px;
    border-radius: 20px;
    background-color: rgba(255,255,255,0.1);
    transition-property: opacity;
    transition-duration: 0.2s;
}

.starter .navigation li.current {
    background-color: rgba(255,255,255,0.7);
}

#starter {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}

#starter .logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
}

#starter .wrapper {
    background: url('/back-welcome.png');
    background-repeat:  no-repeat;
    background-position: center bottom;
    background-size: 130% 130%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

#starter .slides-wrapper{
    flex:1;
    position: relative;
}

.starter .header-wrapper .swiper,
#starter .swiper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

#starter .slides {
    display: flex;
    overflow: hidden;
    height: 100%;
}

#starter .slides li {
    opacity: 0;
    margin-left: -100%;
    transition-property: opacity;
    transition-duration: 0.2s;
    flex: 0 1 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#starter .slides li.current {
    opacity: 1;
    margin-left: 0;
} 

#starter .slides li h2 {
    margin-top: 20px;
    padding: 0 40px;
    font-size: $huge-font-size;
    line-height: 26px;
}

#starter .slides li img {
    width: 80px;
}

#starter .navigation {
    margin-top: 20px;
}

#starter .navigation li {
    background-color: rgba(255,12,103,0.3);
}
#starter .navigation li.current {
    background-color: rgba(255,12,103,1);
}

#starter .skip {
    padding: 10px 0 20px 0;
    text-decoration: underline;
    cursor: pointer;

}

