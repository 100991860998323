@import '../../variables.scss';

.nb-seats-containter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.nb-seats-containter .increase,
.nb-seats-containter .decrease {
    background-color: $main-color;
    color: #ffffff;
    padding: 5px;
    font-size: $huge-font-size;
    font-weight: bold;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $default-radius;
}

.nb-seats-containter .seats.empty img {
    filter: opacity(0.1);
}

.nb-seats-containter .seats {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 10px;
    min-height: 30px;
}

.nb-seats-containter .seats .middle,
.nb-seats-containter .seats .middle img {
    width: 16px;
}



.nb-seats-containter .number {
    @include default-input;
    margin: 0 10px;
}