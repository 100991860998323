@import '../../variables.scss';

.cta {
    background-color: #ffffff;
    flex-direction: column;
    width : 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $main-color;
    padding: 10px 0;
}

.cta.expanded {
    max-height: 100px;
    transition-property: max-height;
    transition-duration: 0.1s;
    transition-timing-function: linear;
}

.cta.collapsed {
    overflow-y: hidden;
    max-height: 0px;
    padding: 0;
    justify-content: flex-start;
    transition-property: max-height;
    transition-duration: 0.1s;
    transition-timing-function: linear;
    border: none;
}

@media screen and (min-device-width: 1200px) {
    .cta {
        background-color:transparent;
        border-top: none;
    }
}