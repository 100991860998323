@import '../../variables.scss';

.toggle-container {
    display:flex;
    flex-direction: row;
    height: 30px;
    width: 50px;
    min-width: 50px;
    border-radius: 30px;
    background-color: #C3C3C3;
    justify-content: flex-start;
    align-items: center;
}

.toggle-container.toggled {
    background-color: $main-color;
    justify-content: flex-end;
}

.toggle-container .toggle {
    width: 22px;
    height: 22px;
    background-color: #ffffff;
    margin: 4px;
    border-radius: 22px;
}
