@import '../../variables.scss';

.loader-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top:0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 100;
}

.loader-container img {
    margin-right: 20px;
}

@media screen and (min-device-width: 1200px) {
    .loader-container {
        position: fixed;
    }
}
