@import '../../variables.scss';

.shipping-options-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.shipping-options-container hr {
    background-color: $light-color;
    border: none;
    height: 1px;
    width: 100%;
    margin: 20px 0 0 0;
}

.shipping-options-container .form-field-wrapper {
    margin-bottom: 0;
}

.shipping-options-container .option .form-field label {
    flex: 1;
    margin-left: 10px;
}

.shipping-options-container .option .form-field.inline {
    flex-wrap: wrap;
}

.shipping-options-container .form-field.reverse {
    justify-content: flex-end;
}
.shipping-options-container .form-field.reverse .label {
    margin-left: 10px;
}

.shipping-options-container .limit input,
.shipping-options-container .price input {
    width: 80px;
    margin-left: 40px;
}

.shipping-options-container .description {
    margin: 5px 0 0 40px;
    display: block;
    @include secondary-text;
}

.shipping-options-container .youzdDelivery .price,
.shipping-options-container .explanation {
    display: block;
    margin: 10px;
    line-height: 18px;
}

.shipping-options-container .youzdDelivery .price strong {
    color: $main-color;
}

.shipping-options-container .pickAtSeller .form-group {
    margin-bottom: 0;
    margin-left: 40px;
}

.shipping-options-container .errors,
.shipping-options-container .warnings {
    margin: 10px;
}