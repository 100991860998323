@import '../../variables.scss';


.add-card .scroller {
    overflow: scroll;
    position: relative;
}

.add-card .scroller .form-body {
    padding: 20px;
    padding-bottom: 50px;
}

.add-card .transaction-payment {
    padding: 20px;
    background-color: #ffffff;
    border-radius: $default-radius;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#secure {
    padding: 0 20px;
}

#secure .button {
    margin-top: 20px;
}

.add-card .secure .arrow {
    height: 25px;
}

.add-card p.intro {
    margin-top: 10px;
    line-height: 1.2em;
}

.add-card .form-field-wrapper {
    width: 100%;
    margin-bottom: 0;
}

.add-card .form-group .form-field-wrapper {
    width: auto;
}

.add-card .form-field-wrapper .reverse {
    justify-content: flex-end;
}


.add-card .expiry-and-cvc {
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 0;
}

.add-card .expiry {
    flex-wrap: wrap;
    align-items: center;
    margin-top: 0;
    flex:1;
    margin-bottom: 0;
}

.add-card .expiry .expiry-label {
    width: 100%;
    margin-bottom: 10px;
    font-size: $small-font-size;
}
.add-card .expiry .exprity-month {
    margin-left: 0;
    margin-right: 10px;
}

.add-card .card-cvc {
    flex:1;
}

.add-card .form-group.inline .form-field-wrapper:not(:first-of-type) {
    margin-left: 0;
}
.add-card .expiry .expiry-sep {
    margin: 0 10px;
}
.add-card .expiry-and-cvc .errors {
    width: 100%;
    color: $error-color;
    margin-top: 10px;
}

.add-card .transaction-summary {
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: $default-radius;
    margin-bottom: 20px;
}