@import '../../variables.scss';

.button {
    cursor: pointer;
    background-color: $main-color;
    color: #ffffff;
    font-size: $button-font-size;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 8px;
    padding: 8px 15px;
    min-width: 220px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 45px;
}

.button img.loader {
    margin-right: 20px;
}
.button img.arrow {
    margin-left: 20px;
}

.button.disabled {
    background-color: $disabled-color;
    color: $disabled-text;
}

.button.secondary {
    background-color: $dark-color;
    color: $inverted-text;
}

.button.white {
    background-color: #ffffff;
    color: $dark-color;
}

.button.arrow {
    justify-content: space-between;
}

