@import '../../variables.scss';

.floor-edit {
    width: 100%;
}

.floor-edit {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}


.floor-edit .form-field.inline {
    justify-content: flex-start;
}

.floor-edit .form-field.inline .label {
    margin-right: 10px;
    font-size: $small-font-size;
}