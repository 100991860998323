@import '../../variables.scss';

.form-group {
    display: flex;
    flex-direction: column;
    margin: $form-spacing;
}

.form-group .form-field-wrapper:first-of-type {
    margin-top: 0;
}

.form-group .form-field-wrapper:last-of-type {
    margin-bottom: 0;
}

.form-group.inline {
    flex-direction: row;
    max-width: 100%;
}

.form-group.inline .form-field-wrapper {
    min-width: 0;
    margin: 0;
}

.form-group.inline .form-field-wrapper:not(:first-of-type) {
    margin-left: 20px;
}