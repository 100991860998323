@import '../../variables.scss';

.carousel {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.carousel ul {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
}

.carousel .shadow,
.carousel .swiper {
    position:absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.carousel ul.thumbs {
    display: flex;
    position: absolute;
    bottom: 10px;
    width: 100%;
    height: auto;
    justify-content: center;
}

.carousel ul.thumbs li {
    display: block;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    background-color: #ffffff;
    margin: 0 5px;
}

.carousel ul.thumbs li.active {
    background-color: $main-color;
}

.carousel ul {
    height: 100%;
    display: flex;
    overflow-x: hidden;
}
.carousel ul li {
    width: 100%;
    height: 100%;
    flex: 0 0 auto;
    transition-property: margin;
    transition-duration: 0.2s;
    transition-timing-function: linear;
}

.carousel ul li.swiped {
    margin-left: -100%;
}

.carousel ul li.current.swipingLeft {
    margin-left: -20%;
}
.carousel ul li.first.swipingRight {
    margin-left: 20%;
}
.carousel ul li.before.swipingRight {
    margin-left: -80%;
}