@import '../../variables.scss';

.categories-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.categories-container .categories {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.categories-container .categories li {
    width: 33%;
    background-color: $back-grey;
    border: 5px solid #ffffff;
    padding: 5px;    
}


.categories-container .categories li .square {
    width: 100%;
    position: relative;
    padding-bottom: 100%;
}

.categories-container .categories li .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.categories-container .categories li span {
    text-align: center;
    font-size: $micro-font-size;
    flex:0;
}
.categories-container .categories li img {
    flex:0;
    max-height:30px;
    margin: 5px;
}

.categories-container .categories li.selected {
    background-color: $main-color;
    position: relative;
}

.categories-container .categories li.selected span {
    color: #ffffff;
}
.categories-container .categories li.selected img {
    filter: invert(100%);
}

.categories-container .modal-content {
    margin: 20px;
    @include input-border;
    padding: 20px;
    background-color: #ffffff;
}

.categories-container .modal-content p {
    font-style: bold;
    text-align: center;
    margin: 20px;
}

@media screen and (min-device-width: 1200px) {
    .categories-container .categories li {
        width: 25%;
    }
    .categories-container .categories li img {
        height: 50px;
        max-height: 50px;
    }

    .categories-container .categories li span {
        font-size: $small-font-size;
    }
}