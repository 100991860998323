@import '../../variables.scss';

.tip h3 {
    color: $main-color;
    font-weight: normal;
    text-transform: none;
    text-align: left;
    padding: 0;
    margin: 0 0 5px 0;
}
.tip span {
    color: $light-color;
    font-size: $subtitle-font-size;
    margin: 0;
}