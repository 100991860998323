@import '../../variables.scss';
.share-container .share-link {
    display: flex;
    align-items: center;
}

.share-container .share-link img {
    margin-left: 5px;
}

.share-container .share-link {
    font-size: $small-font-size;
}

#share h2 {
    color: $main-color;
    margin-bottom: 20px;
}

#share p {
    font-size: $title-font-size;
    margin-bottom: 20px;
}

#share {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#share ul {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

#share ul li {
    flex: 1;
}

#share ul li button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    background-color: $back-grey;
    border-radius: $default-radius;
    padding: 8px 15px;
    font-weight: bold;
    font-size: $button-font-size;
}

#share ul li button svg {
    margin-left: 10px;
}