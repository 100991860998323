@import '../../variables.scss';

.photo-editor {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.photo-editor .action {
    position: relative;
    width: 100%;
}

.photo-editor .action label{
    position: absolute;
    display: block;
    top:0;
    left:0;
    width:100%;
    height:100%;
}

.photo-editor .photos-wrapper {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
}

.photo-editor .photos-wrapper .left-shadow {
    position: absolute;
    left:0;
    top: 0;
    width: 20px;
    height: 100%;
    background: linear-gradient(to right,rgba(247,247,247,1),rgba(247,247,247,0));
    
}

.photo-editor .photos-wrapper .right-shadow {
    position: absolute;
    right:0;
    top: 0;
    width: 20px;
    height: 100%;
    background: linear-gradient(to left,rgba(247,247,247,1),rgba(247,247,247,0));
}

.photo-editor .photos {
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    padding: 20px;
    display: flex;
}

.photo-editor .photos .pad {
    width: 10px;
    min-width: 0;
}

.photo-editor .photos-wrapper::-webkit-scrollbar {
    display: none;
}

.photo-editor .photos li.dragging {
    opacity: 0.1;
}

.photo-editor .single li,
.photo-editor .photos li {
    display: inline-block;
    position: relative;
    min-width: 120px;
}

.photo-editor .photos li:first-of-type .photo {
    border: 2px solid $main-color;
    border-radius: $default-radius;
}
.photo-editor .photos li:not(:first-of-type) {
    margin-left: 10px;
}


.photo-editor .single li .controls > div img,
.photo-editor .gallery li .controls > div img,
.photo-editor .photos li .controls > div img {
    margin: 10px;
    width: 25px;
    height: 25px
}

.photo-editor .single li .controls .edit,
.photo-editor .gallery li .controls .edit,
.photo-editor .photos li .controls .edit {
    position: absolute;
    right: 0;
    bottom: 0;
}

.photo-editor .single li .controls .delete,
.photo-editor .gallery li .controls .delete,
.photo-editor .photos li .controls .delete {
    position: absolute;
    right: 0;
    top: 0;
}

.photo-editor .photo {
    width: 100%;
    pointer-events: none;
}

.photo-editor input {
    display: none;
}

#zoom {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

#zoom .square {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    flex: 0 1;
}

#zoom .square .carousel-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
}

#zoom h2,
#zoom .button.close,
#zoom .modal-controls {
    flex: 0;
}

#zoom h2 {
    margin-bottom: 20px;
    text-align: center;
}

#zoom .modal-controls {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    color: $main-color;
    font-size: $secondary-font-size;
}


#zoom .modal-controls .button {
    min-width: 0;
    min-height: 0;
    margin: 10px 0;
    margin-right: 10px;
}

#zoom .modal-controls .button img {
    width: 25px;
    height: 25px;
    object-fit: fill;
    transform: scaleY(-1);
    margin: 0;
}

#zoom .carousel {
    background-color: $dark-color;
}

#zoom .carousel .photo {
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-radius: 0;
}

.photo-editor .gallery {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}
.photo-editor .gallery li {
    flex: 0 0 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    position: relative;
    max-width: 120px;
    max-height: 120px;
}

.photo-editor .single .add,
.photo-editor .single .load,
.photo-editor .photos .add,
.photo-editor .photos .load,
.photo-editor .gallery li > label,
.photo-editor .gallery li > div {
    border-radius: $default-radius;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center; 
}

.photo-editor .single .add img,
.photo-editor .photos .add img,
.photo-editor .gallery .add img {
    max-width: 60px;
}

.photo-editor .single .add{
    width: 120px;
    height: 120px;
}

.photo-editor .load .img-wrapper,
.photo-editor .photos .add label,
.photo-editor .gallery .add label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
 
.photo-editor .photos .square,
.photo-editor .gallery .square {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
} 

.photo-editor .single {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
}

.photo-editor .single .add-link {
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.photo-editor .single .add-link img{
    margin-right: 10px;
}

@media screen and (min-device-width: 1200px) {
    #zoom {
        max-width: 500px;
        height: 100%;
        background-color: #ffffff;
    }
}