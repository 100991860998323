@import '../../variables.scss';

.ad-container {
    flex: 1;
    overflow: scroll;
}

.ad-container section {
    margin: 20px;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 10px;
}

.ad-container article {
    margin: 15px 0;
}

.ad-container .divider {
    height: 1px;
    background-color: $light-color;
}

.ad-container p {
    margin-bottom: 5px;
}

.ad-container h2 {
    color: $text-color;
    margin-bottom: 5px;
}

.ad-container h3 {
    margin: 15px 0;
}

.ad-container .main header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.ad-container .main header h2 {
    margin-right: 5px;
}

.ad-container section header h3 {
    margin-top:0;
}

.ad-container .ad-photo-wrapper {
    position: relative;
}

.ad-container .likes-container {
    position: absolute;
    left: 0;
    bottom: 0;
    padding-bottom: 0;
}

.ad-container .precision {
    color: $main-color;
}

.ad-container .owner .button {
    margin-top: 20px;
}

.ad-container article ul.details {
    display: flex;
    align-items: center;
}

.ad-container article p.user-comment {
    color: $darker-color;
    margin-top: 5px;
}

.ad-container article ul.details li.sep {
    width: 3px;
    height: 3px;
    background-color: $dark-color;
    border-radius: 3px;
    margin: 0 5px;
}

#confirm {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#confirm h2,
#confirm p {
    text-align: center;
    margin: 20px;
}

#confirm .button {
    max-width: 220px;
    margin: 10px 0;
}

.ad-container .buy {
    margin: 10px 0;
}

.ad-container .no-transaction {
    text-align: center;
}

@media screen and (min-device-width: 1200px) {
    #confirm {
        width: 400px;
    }
    #confirm p {
        color: $light-color;
    }
}