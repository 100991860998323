@import '../../variables.scss';

.shipping-sizes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.shipping-sizes li {
    width: 33%;
    background-color: $back-grey;
    border: 5px solid #ffffff;
    padding: 10px;
}

.shipping-sizes li .square {
    width: 100%;
    position: relative;
    padding-bottom: 100%;
}

.shipping-sizes li .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.shipping-sizes li .wrapper span {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0;
    font-size: $small-font-size;
}

.shipping-sizes li img {
    flex: 1;
    width: 45px;
    margin-bottom: 5px;
}

.shipping-sizes li.selected {
    background-color: $main-color;
}

.shipping-sizes li.selected img {
    filter: invert(100%);
}

.shipping-sizes li.selected span {
    color: $inverted-text;
}